import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Contact() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center items-center flex-col">
      <h2 className="text-6xl font-bold mb-5">Contact Me</h2>
      <div className="flex justify-between items-center flex-col lg:flex-row">
        <div className="w-full bg-white mx-2 p-5 rounded-lg">
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/leonnardoenriquesn/30min"
            style={{
              minWidth: "520px",
              width: "100%",
              height: "700px",
              borderRadius: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
}
